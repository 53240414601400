/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from "react-router-dom";
import { useGetEnvironmentalDataQuery } from "../../../../features/aggregateScreen/aggregateScreenApi";
import { getDate30DaysAgo, getTodaysDate } from "../../../../shared/utils/utils";


interface Data {
    environmentalKpis: any
    environmentalLoading?: boolean
}

export const useEnvironmentalImpactWidget = (): Data => {
    const [url] = useSearchParams()
    const plantIdUrl = url.get("plantId")
    const portfolioIdUrl = url.get("portfolioId")

    const { data: environmentalKpis, isLoading: environmentalLoading } = useGetEnvironmentalDataQuery(
        { fromDate: getDate30DaysAgo(), toDate: getTodaysDate(), portfolioName: portfolioIdUrl, plantId: plantIdUrl },
        { refetchOnMountOrArgChange: 10800, skip: !plantIdUrl });

    return {
        environmentalKpis,
        environmentalLoading
    };
};
