import { combineReducers } from "@reduxjs/toolkit";
import { sharedStateReducer } from "./sharedSlice";

const SharedReducer = combineReducers({
    sharedState: sharedStateReducer
});

export type AppState = ReturnType<typeof SharedReducer>;

export default SharedReducer;
