/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import App from "../app/App";
import { Footer } from '../components/footer/footer';
import { NotFound } from '../app/NotFound';
import {
    UnauthenticatedTemplate,
    AuthenticatedTemplate,
    MsalAuthenticationTemplate,
    useMsal,
} from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { PlantScreen } from '../pages/plantScreen';
import { Header } from '../components/header';
import { autoRefreshApiTime, parseJSON, setSessionStorageValues } from '../shared/utils/utils';
import { refreshAccessToken } from '../features/sharedActions/sharedAction';
import { useDispatch } from 'react-redux';
import { EventType } from '@azure/msal-browser';
import { PlantScreenAms } from '../pages/plantScreenAms/plantScreenAms.component';
import { PlantScreenAmsV2 } from '../pages/plantScreenAmsV2/plantScreenAmsV2.component';
import { Roles, RolesMap } from '../shared/interfaces';
import { getToken, getPasswordLessToken, parseJwt } from '../store/authToken';
import { LOCALSTORAGEKEYS } from '../shared/constants/constant';
import { PlantScreenAmsV3 } from '../pages/PlantScreenAmsV3/PlantScreenAmsV3.component';
import { PlantScreenAmsV4 } from '../pages/PlantScreenAmsV4/PlantScreenAmsV4.component';
import { SiteOverview } from '../pages/siteOverview';
import { PlantMonthly } from '../pages/plantMonthly';
import { PlantYearly } from '../pages/plantYearly/plantYearly.component';

interface AppProps {
}

const authRequest = {
    scopes: ["openid", "profile"],
};

const ErrorComponent = ({ error }) => (
    <p>An Error Occurred: {error.toString()}</p>
);

const LoadingComponent = () => (
    <div>Authentication in progress...</div>
);

export const Router: FC<AppProps> = (props: AppProps) => {

    const urlParams = new URLSearchParams(window.location.search);
    const hashUrl = urlParams.get(LOCALSTORAGEKEYS.HASH_STATE);
    const hashState = localStorage.getItem(LOCALSTORAGEKEYS.HASH_STATE);

    const setHashIntoLocalStorage = () => {
        const usernameUrl = urlParams.get(LOCALSTORAGEKEYS.HASH_USERNAME);
        const passwordUrl = urlParams.get(LOCALSTORAGEKEYS.HASH_PASSWORD);
        localStorage.setItem(LOCALSTORAGEKEYS.HASH_STATE, hashUrl);
        localStorage.setItem(LOCALSTORAGEKEYS.HASH_USERNAME, usernameUrl);
        localStorage.setItem(LOCALSTORAGEKEYS.HASH_PASSWORD, passwordUrl);
        localStorage.removeItem(LOCALSTORAGEKEYS.TOKEN);
    }

    if (hashUrl === "true") {
        setHashIntoLocalStorage();
    }


    const { instance, accounts } = useMsal();
    const [roles, setRoles] = useState({} as RolesMap);

    const dispatch = useDispatch();

    useEffect(() => {
        resetPassword()
    }, [instance]);

    const resetPassword = async () => {
        instance.addEventCallback(async (message) => {
            if (message.eventType === EventType.LOGIN_FAILURE) {
                if (message.error.errorMessage.includes("AADB2C90118")) { // The user has forgotten their password.
                    await instance.loginRedirect({
                        authority: process.env.REACT_APP_RESET_PASSWORD_AUTHORITY,
                        scopes: ["openid", "profile"],
                    })
                }
            }
        });
    }

    useEffect(() => {
        if (hashUrl === 'true' || hashState === 'true') {
            console.log(`calling refresh token password less flow`)
            const myInterval = autoRefreshApiTime(() => getPasswordLessToken(), 20);
            return () => clearInterval(myInterval);
        }
        else {
            console.log(`calling refresh token normal flow`)
            const myInterval = autoRefreshApiTime(() => refreshToken(), 15);
            return () => clearInterval(myInterval);
        }
    }, [])

    const refreshToken = async () => {
        try {
            const token = await instance.ssoSilent({});
            if (token) {
                const items = Object?.values(sessionStorage);
                const sessionToken = parseJSON(items?.find((item) => parseJSON(item)?.credentialType === 'RefreshToken'));
                const body = {
                    RefreshToken: sessionToken?.secret
                }
                const resp: any = await dispatch(refreshAccessToken(body))
                if (!resp) return;
                setSessionStorageValues('AccessToken', resp?.data?.access_token)
                setSessionStorageValues('RefreshToken', resp?.data?.refresh_token)
                localStorage.setItem("token", resp?.data.access_token);
            }
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                await instance
                    .loginPopup()
                    .catch((error) => {
                        console.log(error, `error`)
                    });
            } else {
                return;
            }
        }
    }

    useEffect(() => {
        if (hashState !== "true" && (!instance || accounts.length <= 0)) {
            console.log("Dont have instance or account");
            return;
        };
        getRoles(instance).then(roles => setRoles(roles));
    }, [instance, accounts]);

    const getRoles = async (instance): Promise<RolesMap> => {
        let roles = {
            [Roles.MONITORING]: false,
            [Roles.SIDEM]: false,
            [Roles.OPERATIONS]: false,
            [Roles.CMMS]: false,
            [Roles.BILLING]: false,
            [Roles.ONBOARDING]: false,
            [Roles.COMMERCIAL]: false,
            [Roles.ANALYTICS]: false,
            [Roles.SKYFRIDEMO]: false,
        };

        try {
            const token = await getToken();
            const tokenContent = parseJwt(token);
            const rolesArr = tokenContent?.extension_roles?.split(",") || [];

            if (rolesArr.length <= 0) {
                instance.logoutRedirect().catch((e) => console.log(e));
            }

            rolesArr?.forEach((item: Roles) => {
                roles[item] = true;
            });
        } catch (e) {
            console.log(e, roles);
            return roles;
        }
        return roles;
    };

    useLayoutEffect(() => {
        const fetchToken = async () => {
            if (hashUrl === 'true' || hashState === 'true') {
                try {
                    await getPasswordLessToken()
                } catch (error) {
                    console.error('Error fetching token:', error);
                }
            }
        };

        fetchToken();
    }, [hashUrl]);

    function getRoutes() {
        return (
            <BrowserRouter>
                <Header roles={roles} />
                <Routes>
                    <Route index element={<App />} />
                    <Route path='/plant-screen-v1' element={<PlantScreen />} />
                    <Route path='/plant-screen-v2' element={<PlantScreen />} />
                    <Route path='/plant-screen-ams-v1' element={<PlantScreenAms />} />
                    <Route path='/plant-screen-ams-v2' element={<PlantScreenAmsV2 />} />
                    <Route path='/plant-screen-ams-v3' element={<PlantScreenAmsV3 />} />
                    <Route path='/plant-screen-ams-v4' element={<PlantScreenAmsV4 />} />
                    <Route path='/plant-screen-overview' element={<SiteOverview />} />
                    <Route path='/plant-monthly-v1' element={<PlantMonthly />} />
                    <Route path='/plant-yearly-v1' element={<PlantYearly />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        )
    }

    function checkState() {
        if (hashUrl === 'true' || hashState === 'true') {
            return getRoutes()
        }

        else {
            return (
                <>
                    <UnauthenticatedTemplate>
                        <MsalAuthenticationTemplate
                            interactionType={InteractionType.Redirect}
                            authenticationRequest={authRequest}
                            errorComponent={ErrorComponent}
                            loadingComponent={LoadingComponent}
                        ></MsalAuthenticationTemplate>
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        {getRoutes()}
                    </AuthenticatedTemplate>
                </>
            )
        }
    }
    return (
        <>
            {
                checkState()
            }
        </>
    )
}
