/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sharedStateSelector } from "../../../../features/sharedState/sharedSlice";
import { getGraphLoading } from "../../../../features/sharedActions/sharedAction";
import { addNulls, convertEnergyForGraph, convertEnergyUnits, graphFontSize, graphWithTimeFontSize } from "../../../../shared/utils/utils";
import { useGetPlantYearlyDataQuery } from "../../../../features/plantScreen/plantScreenApi";
interface Data {
    yAxis: YAxis[];
    xAxis?: XAxis;
    graphData?: unknown[];
    isLoading?: boolean;
}
interface XAxis {
    categories?: number[] | string[];
    endOnTick?: boolean;
    showLastLabel?: boolean;
    startOnTick?: boolean;
    lineColor?: string;
    lineWidth?: number;
    labels?: {
        useHTML?: boolean;
        style?: {
            fontSize?: string;
            color?: string;
            fontWeight?: number;
            paddingTop?: string;
        };
    };
    gridLineWidth?: number;
    gridLineColor?: string;
    showFirstLabel?: boolean;
    tickmarkPlacement?: string;
}
interface YAxis {
    labels?: {
        enabled: boolean;
        style?: {
            color: string;
        };
    };
    visible: boolean;
    opposite: boolean;
    gridLineColor?: string;
    allowDecimals: boolean;
    type: string;
    gridLineWidth: number;
    title: {
        text?: string;
        style?: {
            color?: string;
        };
    };
}

export const useSiteYearlyGraphWidget = (): Data => {

    const dispatch = useDispatch()
    const [url] = useSearchParams()
    const plantIdUrl = url.get("plantId")

    const { data: genDataYearly, refetch, isError, isLoading } = useGetPlantYearlyDataQuery(
        { plantId: plantIdUrl }, { skip: !plantIdUrl }
    );
    const { colors } = useSelector(sharedStateSelector)

    const [graphData, setGraphData] = useState([]);
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<YAxis[]>([]);
    const [maxYAxis, setMaxYAxis] = useState<number>(0);

    useEffect(() => {
        getData()
    }, [genDataYearly, maxYAxis])

    useEffect(() => {
        if (!plantIdUrl) return
        refetch()
    }, [plantIdUrl])
    useEffect(() => {
        if (isError) {
            dispatch(getGraphLoading(false))
            return
        }
        dispatch(getGraphLoading(isLoading))
    }, [isLoading])

    const getData = () => {
        if (isError) return;
        const tempYAxis = [
            {
                offset: 10,
                labels: {
                    enabled: true,
                    style: { color: "#E7E7E7", fontSize: graphWithTimeFontSize(), fontWeight: 500 },
                    formatter: function () {
                        setMaxYAxis(this.axis.max);
                        if (!this.isLast)
                            return convertEnergyForGraph(this.value, this.axis.max, 1);
                    }
                },
                enabled: true,
                visible: true,
                opposite: false,
                allowDecimals: false,
                type: "linear",
                gridLineWidth: 0,
                title: {
                    text: `${convertEnergyUnits(maxYAxis)}`,
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: 18,
                    x: -10,
                    style: {
                        color: "rgba(255, 255, 255, 0.4)",
                        fontSize: graphWithTimeFontSize(),
                        fontWeight: 500,
                    },
                },
                softMax: 0.1,
                min: 0,
                lineColor: 'rgba(255, 255, 255, 0.15)',
            },
        ];
        setYAxis(tempYAxis);

        const tempXAxis: XAxis = {
            categories: addNulls(genDataYearly?.yearlyValues?.map((item) => item.year)),
            lineColor: 'rgba(255, 255, 255, 0.15)',
            labels: {
                useHTML: true,
                style: {
                    fontSize: graphFontSize(),
                    color: "white",
                    fontWeight: 500,
                    paddingTop: "10px",
                },
            },
            gridLineWidth: 3,
            gridLineColor: 'rgba(255, 255, 255, 0.15)',
            showLastLabel: false,
            startOnTick: false,
            showFirstLabel: false,
            tickmarkPlacement: 'on',
        };

        const tempData = [
            // Energy produced this year ↓
            {
                name: "Energy produced this year",
                data: addNulls(genDataYearly?.yearlyValues?.map((item) => item.pvGen)),
                color: colors?.energyColor,
                tooltip: {
                    valueSuffix: "kWh",
                },
                pointPlacement: 'on',
            },
        ];
        setGraphData(tempData);
        setXAxis(tempXAxis);
    }

    return {
        yAxis,
        graphData,
        xAxis,
        isLoading,
    };
};
