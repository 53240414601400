/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { autoRefreshApiTime } from "../../shared/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getTenantColor, getUserName } from "../../features/sharedActions/sharedAction";
import { sharedStateSelector } from "../../features/sharedState/sharedSlice";
import { useMsal } from "@azure/msal-react";
import { getLobbyLogo } from "../../features/header/headerActions";
import { useGetLobbyScreenColorsQuery } from "../../features/sharedActions/sharedApi";
import { LOCALSTORAGEKEYS } from "../../shared/constants/constant";
import { getToken, parseJwt } from "../../store/authToken";
interface Data {
    logo: any;
    isError?: boolean;
    clientName?: string;
    logOut?: () => Promise<void>;
    userName?: string;
}

export const useHeaderWidget = (): Data => {
    const [userId, setUserId] = useState<string>(null);
    const [logo, setLogo] = useState<any>(null);
    const { data: colorsApi, isError, refetch } = useGetLobbyScreenColorsQuery({ userId: userId }, { skip: !userId });
    const { clientName, userName, plantIds } = useSelector(sharedStateSelector)
    const { instance } = useMsal();
    const dispatch = useDispatch()

    const logOut = () => {
        localStorage.removeItem(LOCALSTORAGEKEYS.TOKEN);
        if (localStorage.getItem(LOCALSTORAGEKEYS.HASH_STATE) === "true") {
            localStorage.removeItem(LOCALSTORAGEKEYS.HASH_STATE)
            localStorage.removeItem(LOCALSTORAGEKEYS.HASH_USERNAME)
            localStorage.removeItem(LOCALSTORAGEKEYS.HASH_PASSWORD)
        }
        else {
            return instance.logoutRedirect().catch(e => console.log(e))
        }
    };

    const setUserName = async () => {
        const token = await getToken();
        const parsedToken = parseJwt(token)
        setUserId(parsedToken?.extension_userid)
        dispatch(getUserName(parsedToken?.name))
    }

    useEffect(() => {
        setUserName()
    }, []);

    useEffect(() => {
        if (!plantIds?.length) return
        getLogo()
    }, [plantIds]);

    useEffect(() => {
        if (isError || !colorsApi) return
        dispatch(getTenantColor(colorsApi))
    }, [colorsApi]);

    useEffect(() => {
        if (!isError) return
        const myInterval = autoRefreshApiTime(() => refetch(), .50);
        return () => clearInterval(myInterval);
    }, [isError]);

    const getLogo = async () => {
        const resp: any = await getLobbyLogo("PRIMARY")
        if (resp?.status === 400 || resp?.status === 404 || resp?.status === 204 || resp?.status === 500 || !resp?.data?.size || resp?.data?.type === "image/png")
            setLogo(null)
        else {
            const url = URL?.createObjectURL(resp?.data);
            setLogo(url)
        }
    }
    useEffect(() => {
        if (logo) return
        const myInterval = autoRefreshApiTime(() => getLogo(), 1);
        return () => clearInterval(myInterval);
    }, [logo]);

    return {
        logo,
        clientName,
        logOut,
        userName
    };
};
