/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from "react-router-dom";
import { useGetPlantImageQuery } from "../../../features/plantScreen/plantScreenApi";

interface Data {
    plantImage: string;
}

export const useSiteImage = (): Data => {
    const [url] = useSearchParams();
    const plantId = url.get("plantId");

    const { data: plantImage } = useGetPlantImageQuery(plantId, { skip: !plantId });

    return {
        plantImage,
    };
};
