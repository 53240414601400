import { useSearchParams } from "react-router-dom";
import { useGetAcPowerIrrKpiPlantQuery, useGetAcPowerIrrPlantQuery, useGetCommissionDateQuery, useGetGenDataPlantAmsLobbyQuery, useGetGraphDataPlantAmsLobbyQuery, useGetPlantImageQuery, useGetPlantLocationQuery, useGetPlantMonthlyDataQuery, useGetPlantProfileInfoLobbyQuery, useGetPlantYearlyDataQuery } from "../../features/plantScreen/plantScreenApi";
import { getDate30DaysAgo, getLast12MonthAgo, getTodaysDate } from "../../shared/utils/utils";
import { useGetEnvironmentalDataQuery } from "../../features/aggregateScreen/aggregateScreenApi";

export const useAggregateScreen = () => {


    const [url] = useSearchParams()
    const plantId = url.get("plantId")
    const portfolioId = url.get("portfolioId")
    const version = url.get("version")

    // plantScreenApi & plantScreenAmsApi combined
    useGetPlantProfileInfoLobbyQuery({ portfolioId, plantId }, { skip: !plantId || !portfolioId });
    useGetPlantLocationQuery({ plantId }, { skip: !plantId || !portfolioId });

    // plantScreenApi
    useGetAcPowerIrrKpiPlantQuery({ plantId, portfolioId }, { skip: !plantId || !portfolioId });
    useGetAcPowerIrrPlantQuery({ plantId: plantId }, { skip: !plantId });

    // plantScreenAmsApi
    useGetGraphDataPlantAmsLobbyQuery(
        { plantId: plantId, fromDate: getDate30DaysAgo(), toDate: getTodaysDate() }, { skip: !plantId }
    );
    useGetGenDataPlantAmsLobbyQuery({ plantId: plantId, portfolioId: portfolioId, fromDate: getDate30DaysAgo(), toDate: getTodaysDate() }, { skip: !plantId || !portfolioId });

    useGetEnvironmentalDataQuery({ fromDate: getLast12MonthAgo().twelveMonthsAgo, toDate: getLast12MonthAgo().currentMonth, portfolioName: portfolioId, plantId: plantId },
        { skip: version !== "4" && version !== "5" && (!plantId || !portfolioId) }
    );
    useGetCommissionDateQuery({ plantId }, { skip: !plantId });

    useGetPlantProfileInfoLobbyQuery({ portfolioId, plantId }, { skip: !plantId });

    useGetPlantImageQuery(plantId, { skip: !plantId });

    useGetPlantMonthlyDataQuery({ plantId: plantId, }, { skip: !plantId });

    useGetPlantYearlyDataQuery({ plantId: plantId }, { skip: !plantId });

};
