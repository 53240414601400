/* eslint-disable react-hooks/exhaustive-deps */
import { useGetEnvironmentalDataQuery } from "../../../../features/aggregateScreen/aggregateScreenApi";
import { getLast12MonthAgo } from "../../../../shared/utils/utils";


interface Data {
    environmentalKpis: any
    environmentalLoading?: boolean
}

export const useEnvironmentalImpactWidget = (): Data => {
    const { data: environmentalKpis, isLoading: environmentalLoading } = useGetEnvironmentalDataQuery(
        { fromDate: getLast12MonthAgo().twelveMonthsAgo, toDate: getLast12MonthAgo().currentMonth, portfolioName: 'all', plantId: "all" },
        { refetchOnMountOrArgChange: 10800 });

    return {
        environmentalKpis,
        environmentalLoading
    };
};
