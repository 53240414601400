// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { checkNegative, screenBottomGap } from '../../utils/utils';
import { ReactComponent as NOIMAGE } from '../../icons/NOIMAGE.svg';
import './siteImageWidgetStyle.css'

interface SiteImageWidgetProps {
    customHook?: () => any;
}
export const SiteImageWidget = (props: SiteImageWidgetProps) => {
    const [availableHeight, setAvailableHeight] = useState(0);
    const divRef = useRef(null);

    const { customHook } = props;

    const { plantImage } = customHook();

    const calculateAvailableHeight = () => {
        if (divRef.current) {
            const elementBottom = divRef?.current?.getBoundingClientRect()?.bottom;
            const viewportHeight = window.innerHeight;
            setAvailableHeight(availableHeight => checkNegative(availableHeight + ((viewportHeight - elementBottom) - screenBottomGap())));
        }
    }

    useEffect(() => {
        if (!divRef.current)
            return

        //initial calculation
        calculateAvailableHeight();

        // Re-calculate available height on window resize
        window.addEventListener('resize', calculateAvailableHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateAvailableHeight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divRef.current]);

    return (
        <div className='siteWidgetPadding' ref={divRef} style={{ height: availableHeight }}>
            {
                !plantImage ?
                    <div className='rounded-xl border-solid border-2 border-cardBorder  bg-cardColor' style={{ height: availableHeight, display: 'flex', justifyContent: "center", borderRadius: "16px", alignItems: 'center' }}>
                        <NOIMAGE />
                    </div>
                    :
                    <img loading="lazy" src={plantImage} alt="Beulah_Rec" style={{ height: availableHeight, width: "-webkit-fill-available", objectFit: "cover", borderRadius: "16px" }} />
            }
        </div>
    )
}
