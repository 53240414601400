import axios from "axios";
import { SIDEM_SCOPE } from "../../shared/utils/scope";
import { getMsalAccessToken } from "../../store/authToken";
import { axPost } from "../../store/axiosClient";
import * as SharedStateSlice from "../sharedState/sharedSlice";

const sidemApiUrl = `SkyfriSidemApi/v1`

export function refreshAccessToken(body: any) {
    return async (dispatch: (arg0: any) => void) => {
        try {
            const token = await getMsalAccessToken(SIDEM_SCOPE);
            const data: any = await axPost(
                `${sidemApiUrl}/Token/RetrieveLobbyScreenAccessToken`,
                body,
                token
            );
            return data;
        } catch (error) {
            return console.log(error);
        }
    };
}

export const getIpAddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res?.data?.ip
}

export const getClientName = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setClientName(payload));
        return payload;
    };
};

export const getTenantColor = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setTenantColor(payload));
        return payload;
    };
};

export const getAmsMeter = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setAmsMeter(payload));
        return payload;
    };
};

export const getGraphLoading = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setGraphLoading(payload));
        return payload;
    };
};

export const getUserName = (payload: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setUserName(payload));
        return payload;
    };
}

export const getSelfSustainability = (payload: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setSelfSustainability(payload));
        return payload;
    };
}

export const getFullScreen = (payload: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setFullScreen(payload));
        return payload;
    };
}

export const getPlantIds = (payload: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setPlantIds(payload));
        return payload;
    };
}

export const getRunningState = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setRunningState(payload));
        return payload;
    };
}

export const getNumberOfPlants = (payload: number) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(SharedStateSlice.setNumberOfPlants(payload));
        return payload;
    };
}