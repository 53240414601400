// @ts-nocheck
import { useState, ReactNode } from 'react'
import screenfull from 'screenfull';
import { ReactComponent as Fullscreen } from '../../../../../../shared/icons/Fullscreen.svg';
import { ReactComponent as Logout } from '../../../../../../shared/icons/Logout.svg';
import { ReactComponent as Minimize } from "../../../../../../shared/icons/Minimize.svg";
import { ReactComponent as VerticalDots } from '../../../../../../shared/icons/Vertical_Dots.svg'
import useComponentVisible from '../../../../../../shared/components/clickAwayListener/clickAwayListener.component';

interface Props {
    children?: ReactNode;
    userName: string;
    logOut: () => void;
}
export const DropdownButton = (props: Props) => {
    const { userName, logOut, } = props

    const [isFullScreen, setIsFullScreen] = useState(true)

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const toggleFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
            setIsFullScreen(screenfull.isFullscreen)
        }
    }

    return (
        <div ref={ref}>
            <button
                id="dropdown-button"
                onClick={() => {
                    setIsComponentVisible(!isComponentVisible)
                    setTimeout(() => {
                        setIsComponentVisible(false)
                    }, 4000);
                }}
                className="text-white outline outline-cardBorder outline-2 bg-transparent focus:ring-4 focus:outline-none focus:ring-cardBorder font-medium rounded-lg text-sm px-10 py-8 text-center inline-flex items-center"
            >
                <VerticalDots />
            </button>
            {
                isComponentVisible &&
                <>
                    <div
                        id="dropdown-menu"
                        className={`absolute right-4 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 space-y-1`}
                    >
                        <div
                            className="block px-4 py-2 text-primary text-3xl cursor-pointer">
                            {userName}
                        </div>
                        <hr className='pb-2' />
                        <div className="flex px-4 py-2 cursor-pointer text-3xl" onClick={() => {
                            toggleFullScreen()
                            setIsComponentVisible(false)
                        }}>
                            {isFullScreen ? <Fullscreen /> : <Minimize />} <span className='text-primary pl-3'>{isFullScreen ? `Full screen` : `Minimize`}</span>
                        </div>
                        <div className="flex px-4 py-2 cursor-pointer text-3xl pb-3" onClick={() => {
                            logOut()
                            setIsComponentVisible(false)
                        }}>
                            <Logout /> <span className='text-primary pl-3'>Log out</span>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
