/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { convertEnergy, convertEnergyUnits } from "../../../../shared/utils/utils";
import { useSearchParams } from "react-router-dom";
import { useGetPlantYearlyDataQuery } from "../../../../features/plantScreen/plantScreenApi";

interface Data {
    totalEnergy: number;
    totalEnergyUnit: string;
    isLoading?: boolean;
}

export const useKpiYearlyWidget = (): Data => {
    const [url] = useSearchParams()
    const plantIdUrl = url.get("plantId")

    const { data: genData, isLoading, refetch } = useGetPlantYearlyDataQuery({ plantId: plantIdUrl }, { skip: !plantIdUrl });

    const [totalEnergy, setTotalEnergy] = useState<number>(0);
    const [totalEnergyUnit, setTotalEnergyUnit] = useState<string>("kWh");

    useEffect(() => {
        if (!genData) return
        getData()
    }, [genData])

    useEffect(() => {
        if (!plantIdUrl) return
        refetch()
    }, [plantIdUrl])

    const getData = () => {
        setTotalEnergy(convertEnergy(genData?.yearlyValuesPVGenSum))
        setTotalEnergyUnit(convertEnergyUnits(genData?.yearlyValuesPVGenSum))
    }

    return {
        totalEnergy,
        totalEnergyUnit,
        isLoading,
    };
};
