import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { MSAAPP } from './app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <MSAAPP />
  </StrictMode>
);
