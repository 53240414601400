export enum Roles {
  MONITORING = "MONITORING",
  SIDEM = "SIDEM",
  CMMS = "CMMS",
  BILLING = "BILLING",
  ONBOARDING = "ONBOARDING",
  COMMERCIAL = "COMMERCIAL",
  ANALYTICS = "ANALYTICS",
  OPERATIONS = "OPERATIONS",
  SKYFRIDEMO = "SkyfriDemo",
}

export enum PagesRoles {
  SKYFRIDEMO = "SkyfriDemo",
}

export type RolesMap = {
  [key in Roles]: boolean;
};
