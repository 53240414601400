import { useSelector } from 'react-redux';
import { sharedStateSelector } from '../../features/sharedState/sharedSlice';
import { useCapacityWidgetAmsV2 } from './components/capacityWidgetAmsV2/useCapacityWidgetAmsV2';
import { Suspense, lazy, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useKpiWidgetAmsV2 } from './components/kpiWidgetAmsV2/useKpiWidgetAmsV2';
import { checkNegative, screenBottomGap } from '../../shared/utils/utils';

const GraphWidgetV2 = lazy(() =>
    import("./components/graphWidgetAmsV2/graphWidgetAmsV2.component").then((module) => ({
        default: module.GraphWidgetAmsV2,
    }))
);

const KpiWidgetV2 = lazy(() =>
    import("../../shared/components/kpiWidgetV2").then((module) => ({
        default: module.KpiWidgetV2,
    }))
);

const CapacityWidget = lazy(() =>
    import("../../shared/components/capacityWidget").then((module) => ({
        default: module.CapacityWidget,
    }))
);

export const PlantScreenAmsV2 = () => {
    const { colors } = useSelector(sharedStateSelector)
    const [availableHeight, setAvailableHeight] = useState(0);
    const divRef = useRef(null);

    useLayoutEffect(() => {
        document.body.style.backgroundColor = colors?.backgroundColor;
    }, [colors]);

    const calculateAvailableHeight = () => {
        if (divRef.current) {
            const elementBottom = divRef?.current?.getBoundingClientRect()?.bottom;
            const viewportHeight = window.innerHeight;
            setAvailableHeight(availableHeight => checkNegative(availableHeight + ((viewportHeight - elementBottom) - screenBottomGap())));
        }
    }

    useMemo(() => {
        if (!divRef.current)
            return

        //initial calculation
        calculateAvailableHeight();

        // Re-calculate available height on window resize
        window.addEventListener('resize', calculateAvailableHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateAvailableHeight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divRef.current]);


    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="grid grid-cols-12">
                <div className='col-span-4'>
                    <CapacityWidget comp='plant-ams' customHook={useCapacityWidgetAmsV2} />
                </div>
                <div className='col-span-8'>
                    <KpiWidgetV2 customHook={useKpiWidgetAmsV2} />
                </div>
                <div className='col-span-12 graphPaddings' ref={divRef} style={{ height: availableHeight }}>
                    <GraphWidgetV2 height={availableHeight} />
                </div>
            </div>
        </Suspense>
    )
}
