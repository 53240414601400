import axios from "axios";
import { getToken } from "../../store/authToken";
import { axGet } from "../../store/axiosClient";

const lobbyScreenUrl = `SkyfriSidemApi/v1/LobbyScreen`

export function getClientLogo(plantIds: string[]) {
    return async () => {
        try {
            const token = await getToken();
            const data = await axGet(
                `${lobbyScreenUrl}/GetTenantLogo?plantIds=${plantIds}`,
                token
            );
            return data;
        } catch (error) {
            return error.response;
        }
    };
}

export const getLobbyLogo = async (logoType: string) => {
    try {
        const token = await getToken();
        const BASE_API_URL = process.env.REACT_APP_API_URL
            ? process.env.REACT_APP_API_URL
            : "https://skifryfpel-dev-apimngment.azure-api.net/";
        const response: any = await axios({
            method: "GET",
            url: BASE_API_URL + `${lobbyScreenUrl}/GetLobbyScreenLogo?logoType=${logoType}`,
            responseType: "blob",
            headers: {
                authorization: "Bearer " + token,
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
                responseType: "blob",
            },
        })
        return response;
    } catch (error) {
        console.error('Error importing logo:', error);
    }
};