/* eslint-disable react-hooks/exhaustive-deps */
import { getIpAddress } from './../../features/sharedActions/sharedAction';
import { useEffect, useState } from "react";
import { autoRefreshApiTime } from "../../shared/utils/utils";
import { usePostWeatherInfoMutation } from "../../features/sharedActions/sharedApi";

interface Data {
    weatherInfoData: any;
}

export const useErrorFallback = (): Data => {

    const [ip, setIpAddress] = useState(null)
    const [postWeatherInfo, { data: weatherInfoData }] = usePostWeatherInfoMutation();

    useEffect(() => {
        getIp()
    }, [])

    useEffect(() => {
        if (!ip) return
        postWeatherInfo(ip)
    }, [ip])

    const getIp = async () => {
        const data = await getIpAddress()
        setIpAddress(data)
    }

    useEffect(() => {
        const myInterval = autoRefreshApiTime(() => window.location.reload(), .5);
        return () => clearInterval(myInterval);
    }, []);

    return {
        weatherInfoData
    };
};
