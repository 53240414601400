import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://skifryfpel-dev-apimngment.azure-api.net/";
// Axios GET Request
export const axGet = async (url: string, accessToken?: string) => {
    const token = localStorage.getItem("token");
    try {
        const res = await axios({
            method: "get",
            url: BASE_API_URL + url,
            headers: {
                authorization: accessToken ? "Bearer " + accessToken : token,
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
                "Content-Type": "text/json",
            },
        });
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


// Axios POST Request
export const axPost = async (url, data, accessToken?: string) => {
    const token = localStorage.getItem("token");
    // if (!token) return;
    // path = route + new URLSearchParams(body);
    try {
        const res = await axios({
            method: "post",
            url: BASE_API_URL + url,
            headers: {
                authorization: accessToken ? "Bearer " + accessToken : token,
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
                "Content-Type": "text/json",
            },
            data,
            withCredentials: true,
        });
        return res;
    } catch (err) {
        console.error(err);
        throw err;
    }
};