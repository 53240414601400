// @ts-nocheck
import { CustomCard } from '../../../components/customCard/customCard.component';
import { ReactComponent as LOCATION_ICON } from "../../icons/Location.svg";
import { Loader } from '../loader/loader.component';
import './locationWidgetStyle.css'
interface Props {
    customHook?: () => any;
}

export const LocationWidget = (props: Props) => {
    const { customHook } = props

    const { locationInfo, locationLoading } = customHook()

    return (
        <div className='text-white locationWidgetPadding'>
            <CustomCard>
                <div className="sm:px-2 sm:py-1 md:px-3 md:py-2 px-6 py-4">
                    {
                        <div className="grid grid-flow-col auto-cols-max my-2">
                            <div className="row-span-2 font-bold pr-3">
                                <LOCATION_ICON className="locationIcon" />
                            </div>
                            <div className="self-center font-bold locationText">
                                {
                                    locationLoading ?
                                        <Loader />
                                        :
                                        locationInfo?.city ? locationInfo?.city + `, ` + locationInfo?.country : '-'
                                }
                            </div>
                        </div>
                    }
                </div>
            </CustomCard>
        </div>
    )
}
