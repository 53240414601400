// @ts-nocheck
import { FC } from 'react'
import "./styles.css";
import { useHeaderWidget } from './useHeader';
import Clock from 'react-live-clock';
import { useSelector } from 'react-redux';
import { DropdownButton } from './components/dropdownButton';
import { sharedStateSelector } from '../../../../features/sharedState/sharedSlice';

export const Header: FC<Props> = () => {

    const { colors } = useSelector(sharedStateSelector)

    const { logo, clientName, logOut, userName } = useHeaderWidget();

    return (
        <div className="header-bg grid grid-cols-5 gap-4 pt-10 px-10 text-white" style={{ backgroundColor: colors?.backgroundColor }}>
            <div className='col-span-4 flex items-end'>
                <div className='text-7xl font-bold flex items-baseline'>
                    {
                        !logo ?
                            clientName
                            :
                            <img style={{ height: "83px" }} alt='client-logo' src={logo} />
                    }
                </div>
                <div>
                </div>
            </div>
            <div className='place-self-end'>
                <div className="grid grid-rows-2 grid-flow-col gap-x-4 gap-y-2">
                    <div className='col-span-2 justify-self-end'>
                        <p className='text-5xl font-medium'>
                            <Clock format={'HH:mm'} ticking={true} />
                        </p>
                    </div>
                    <div className="row-span-1 col-span-2 justify-self-end">
                        <div className='w-max rounded-md h-10 px-3 bg-white place-self-end justify-self-end  text-primary col-span-2' style={{ display: "flex", alignItems: "center" }}>
                            <div className='text-2xl font-bold'>
                                No data
                            </div>
                        </div>
                    </div>
                    <div className="row-span-2 self-center">
                        <DropdownButton logOut={logOut} userName={userName} />
                    </div>
                </div>
            </div>
        </div >
    )
}
