/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { convertEnergy, convertEnergyUnits, getDate30DaysAgo, getTodaysDate } from "../../../../shared/utils/utils";
import { useSearchParams } from "react-router-dom";
import { useGetGenDataPlantAmsLobbyQuery } from "../../../../features/plantScreen/plantScreenApi";
import { getSelfSustainability } from "../../../../features/sharedActions/sharedAction";
import { useDispatch } from "react-redux";

interface Data {
    gridExport: number;
    gridExportUnit: string;
    gridImport: number;
    gridImportUnit: string;
    pvGen: number;
    pvGenUnit: string;
}

export const useKpiWidgetAmsV2 = (): Data => {
    const dispatch = useDispatch()
    const [url] = useSearchParams()
    const plantIdUrl = url.get("plantId")
    const portfolioIdUrl = url.get("portfolioId")

    const { data: genData, refetch } = useGetGenDataPlantAmsLobbyQuery({ plantId: plantIdUrl, portfolioId: portfolioIdUrl, fromDate: getDate30DaysAgo(), toDate: getTodaysDate() }, { skip: !plantIdUrl });

    const [gridExport, setGridExport] = useState<number>(0);
    const [gridExportUnit, setGridExportUnit] = useState<string>("kWh");
    const [gridImport, setGridImport] = useState<number>(0);
    const [gridImportUnit, setGridImportUnit] = useState<string>("kWh");
    const [pvGen, setPvGen] = useState<number>(0);
    const [pvGenUnit, setPvGenUnit] = useState<string>("kWh");

    // const [var1, setVar1] = useState<any>(undefined);

    useEffect(() => {
        if (!genData || !genData?.length) return
        getData()
    }, [genData])

    useEffect(() => {
        if (!plantIdUrl) return
        refetch()
    }, [plantIdUrl])

    const getData = () => {
        setGridExport(convertEnergy(genData[0]?.gridExport))
        setGridExportUnit(convertEnergyUnits(genData[0]?.gridExport))
        setGridImport(convertEnergy(genData[0]?.gridImport))
        setGridImportUnit(convertEnergyUnits(genData[0]?.gridImport))
        setPvGen(convertEnergy(genData[0]?.pvGen))
        setPvGenUnit(convertEnergyUnits(genData[0]?.pvGen))
        dispatch(getSelfSustainability(genData[0]?.selfSustainability))
    }

    return {
        gridExport,
        gridExportUnit,
        gridImport,
        gridImportUnit,
        pvGen,
        pvGenUnit,
    };
};
