import { ThunkAction } from "redux-thunk";
import { configureStore, Action } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "./rootReducers";
import { aggregateScreenApi } from "../features/aggregateScreen/aggregateScreenApi";
import { headerApi } from "../features/header/headerApi";
import { sharedApi } from "../features/sharedActions/sharedApi";
import { plantScreenApi } from "../features/plantScreen/plantScreenApi";

const rootStore = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(aggregateScreenApi.middleware)
            .concat(plantScreenApi.middleware)
            .concat(headerApi.middleware)
            .concat(sharedApi.middleware)
});

export type AppDispatch = typeof rootStore.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export default rootStore;
