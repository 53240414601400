// @ts-nocheck
import { FC } from 'react'
import { useErrorFallback } from './useErrorFall'
import { CustomCard } from '../../components/customCard/customCard.component'
import { ReactComponent as Location } from "../../shared/icons/Location.svg";
import { useSelector } from 'react-redux';
import { sharedStateSelector } from '../../features/sharedState/sharedSlice';
import { Header } from './components/header';

export const ErrorFallback: FC<any> = () => {
    const { weatherInfoData } = useErrorFallback()
    const { colors } = useSelector(sharedStateSelector)

    const divHeight = document?.getElementById('errorDiv')?.clientHeight;
    const parentHeight = window?.innerHeight;
    const availableHeight = parentHeight - divHeight;

    return (
        <>
            <Header />

            <div id='errorDiv' className='parent flex flex-col' style={{ backgroundColor: colors?.backgroundColor, }}>

                <div className="grid grid-cols-4 gap-4 child flex-1 text-white p-10">
                    <div className='col-span-1'>
                        <CustomCard>
                            <div className="px-6 py-4">
                                <div className="grid grid-flow-col auto-cols-max gap-5">
                                    <div className="inline-block self-center font-bold text-2xl">
                                        <Location />
                                    </div>
                                    <div className="inline-block self-center font-bold text-4xl">
                                        {weatherInfoData?.city}, {weatherInfoData?.country}
                                    </div>
                                </div>
                            </div>
                        </CustomCard>
                    </div>

                    <div className='col-span-3 self-center'>
                        <div className="grid grid-cols-12">
                            <div className='col-span-5 flex justify-center items-center '>
                                <div className="grid grid-flow-col auto-cols-max gap-x-16 justify-center ">
                                    <div>
                                        <div className='font-semibold text-3xl flex justify-center '>
                                            Now
                                        </div>
                                        <div>
                                            <img
                                                style={{ width: 240, height: 240, borderRadius: 240 }}
                                                src={`./weather-images/${weatherInfoData?.current?.weather[0]?.icon}.svg`}
                                                alt="Weather Icons"
                                            />
                                        </div>
                                        <div>
                                            <div className='font-extrabold text-8xl flex justify-center '>
                                                {weatherInfoData?.current?.temp.toFixed(0)} °C
                                            </div>
                                            <div className='font-semibold text-3xl flex justify-center pt-10'>
                                                {weatherInfoData?.current?.weather[0]?.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <div className="inline-block rounded h-full w-0.5 self-stretch bg-white opacity-30 "></div>
                            </div>

                            <div className='col-span-5 flex justify-center items-center '>
                                <div className="grid grid-flow-col auto-cols-max gap-x-16 justify-center ">
                                    <div>
                                        <div className='font-semibold text-3xl flex justify-center '>
                                            Tomorrow
                                        </div>
                                        <div>
                                            <img
                                                style={{ width: 240, height: 240, borderRadius: 240 }}
                                                src={`weather-images/${weatherInfoData?.daily[1]?.weather[0]?.icon}.svg`}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div className='font-extrabold text-8xl flex justify-center '>
                                                {weatherInfoData?.daily[1]?.temp?.day.toFixed(0)} °C
                                            </div>
                                            <div className='font-semibold text-3xl flex justify-center pt-10'>
                                                {weatherInfoData?.daily[1]?.weather[0]?.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ minHeight: availableHeight, backgroundColor: colors?.backgroundColor }}></div>
        </>
    )
}
