import { PublicClientApplication } from "@azure/msal-browser";
import { LOCALSTORAGEKEYS } from "../shared/constants/constant";
import { SIDEM_SCOPE } from "../shared/utils/scope";
import axios from "axios";


export const getMsalAccessToken = async (scopes: string[]): Promise<string> => {
    const config: any = {
        auth: {
            clientId: process.env.REACT_APP_CLIENT_ID,
            authority: process.env.REACT_APP_AUTHORITY,
            redirectUri: process.env.REACT_APP_REDIRECT_URI,
            postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
            validateAuthority: false,
            knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY],
        },
        cache: {
            cacheLocation: process.env.REACT_APP_SESSION_STORAGE,
            storeAuthStateInCookie: "false",
        },
        apiURL: process.env.REACT_APP_API_URL,
        apimKey: process.env.REACT_APP_API_MKEY,
        scope: [process.env.REACT_APP_SCOPE1, process.env.REACT_APP_SCOPE2],
    };
    const pca = new PublicClientApplication(config);
    const accounts = pca.getAllAccounts();
    const account = accounts[0];

    const resp = await pca.acquireTokenSilent({
        scopes: scopes,
        account,
    });
    if (resp?.accessToken) {
        localStorage.setItem(LOCALSTORAGEKEYS.TOKEN, resp?.accessToken);
    }
    return resp.accessToken;
};

export const getPasswordLessToken = async () => {
    try {
        const username = localStorage.getItem(LOCALSTORAGEKEYS.HASH_USERNAME);
        const password = localStorage.getItem(LOCALSTORAGEKEYS.HASH_PASSWORD);
        const BASE_API_URL = process.env.REACT_APP_PASSWORDLESS_URL
        const resp: any = await axios({
            method: "GET",
            url: `${BASE_API_URL}&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
            headers: {
                "Content-Type": "application/json",
            },
        })
        if (resp && resp.status === 200) {
            localStorage.setItem(LOCALSTORAGEKEYS.TOKEN, resp.data);
            return resp.data;
        }
    } catch (error) {
        console.error('Error importing token:', error);
    }
};

export function getTokenFromLocalStorage() {
    return localStorage.getItem(LOCALSTORAGEKEYS.TOKEN);
}

export const checkTokenValidity = (token: string) => {
    if (token === "undefined" || !token) return false;
    return true;
};

let accessTokenPromiseInProgress: Promise<string> | null = null;

export async function getToken(): Promise<string> {
    const token = localStorage.getItem(LOCALSTORAGEKEYS.TOKEN);
    if (checkTokenValidity(token)) {
        return token;
    }

    const hashState = localStorage.getItem(LOCALSTORAGEKEYS.HASH_STATE);
    try {
        if (hashState === "true") {
            if (!accessTokenPromiseInProgress) {
                accessTokenPromiseInProgress = getPasswordLessToken();
            }
            if (!checkTokenValidity(token)) {
                localStorage.setItem(LOCALSTORAGEKEYS.TOKEN, await accessTokenPromiseInProgress);
            }
            return await accessTokenPromiseInProgress;
        } else {
            if (!accessTokenPromiseInProgress) {
                accessTokenPromiseInProgress = getMsalAccessToken(SIDEM_SCOPE);
            }
            if (!checkTokenValidity(token)) {
                localStorage.setItem(LOCALSTORAGEKEYS.TOKEN, await accessTokenPromiseInProgress);
            }
            return await accessTokenPromiseInProgress;
        }
    } catch (error) {
        accessTokenPromiseInProgress = null;
        console.error("Error fetching access token:", error);
        throw error;
    }
}


export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


export function getEmailFromToken(tokenContent: any): string {
    if (!tokenContent) {
        console.log("Token content was null");
        return null;
    }

    if (tokenContent.emails && tokenContent.emails.length > 0) {
        return tokenContent.emails[0];
    }

    if (tokenContent.email) {
        return tokenContent.email;
    }

    return tokenContent.preferred_username
}