/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGetGenDataMonthlyEachPlantQuery } from "../../../../features/aggregateScreen/aggregateScreenApi";
import { useDispatch } from "react-redux";
import { getGraphLoading } from "../../../../features/sharedActions/sharedAction";
import { convertEnergyForGraph, convertEnergyUnits, getLast12MonthAgo, graphFontSize } from "../../../../shared/utils/utils";
interface Data {
    yAxis: YAxis[];
    xAxis?: XAxis;
    graphData?: any[];
    isLoading?: boolean;
}
interface XAxis {
    categories: number[] | string[];
    endOnTick: boolean;
    showLastLabel: boolean;
    startOnTick: boolean;
}
interface YAxis {
    labels?: {
        enabled: boolean;
        style?: {
            color: string;
        };
    };
    visible: boolean;
    opposite: boolean;
    gridLineColor?: string;
    allowDecimals: boolean;
    type: string;
    gridLineWidth: number;
    title: {
        text: string;
        style?: {
            color?: string;
        };
    };
}

export const useGraphWidgetV3 = (): Data => {

    const dispatch = useDispatch()
    const { data: genDataMonthlyEachPlant, isError, isLoading } = useGetGenDataMonthlyEachPlantQuery(
        { fromDate: getLast12MonthAgo().twelveMonthsAgo, toDate: getLast12MonthAgo().currentMonth, plantId: "all" },
        { refetchOnMountOrArgChange: 1800 });

    const [graphData, setGraphData] = useState([]);
    const [xAxis, setXAxis] = useState<any>();
    const [yAxis, setYAxis] = useState<YAxis[]>([]);
    const [maxYAxis, setMaxYAxis] = useState<number>(0);

    useEffect(() => {
        getData()
    }, [genDataMonthlyEachPlant, maxYAxis])

    useEffect(() => {
        if (isError) {
            dispatch(getGraphLoading(false))
            return
        }
        dispatch(getGraphLoading(isLoading))
    }, [isLoading])

    const getData = () => {
        if (isError) return;
        const tempYAxis = [
            {
                offset: 10,
                labels: {
                    enabled: true,
                    style: { color: "#E7E7E7", fontSize: graphFontSize(), fontWeight: 500 },
                    formatter: function () {
                        setMaxYAxis(this.axis.max);
                        if (!this.isLast)
                            return convertEnergyForGraph(this.value, this.axis.max, 1);
                    }
                },
                enabled: true,
                visible: true,
                opposite: false,
                allowDecimals: false,
                type: "linear",
                gridLineWidth: 0,
                title: {
                    text: `${convertEnergyUnits(maxYAxis)}`,
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: 18,
                    x: -10,
                    style: {
                        color: "rgba(255, 255, 255, 0.4)",
                        fontSize: graphFontSize(),
                        fontWeight: 500,
                    },
                },
                softMax: 0.1,
                min: 0,
                lineColor: 'rgba(255, 255, 255, 0.15)',
            },
        ];
        setYAxis(tempYAxis);

        const tempXAxis: any = {
            categories: genDataMonthlyEachPlant?.value?.xAxis,
            lineColor: 'rgba(255, 255, 255, 0.15)',
            lineWidth: 3,
            labels: {
                useHTML: true,
                style: {
                    fontSize: graphFontSize(),
                    color: "white",
                    fontWeight: 500,
                    paddingTop: "10px",
                    textAlign: "left",
                },
            },
            gridLineWidth: 3,
            gridLineColor: 'rgba(255, 255, 255, 0.15)',
            showLastLabel: true,
            startOnTick: true,
            tickAmount: 12,
            showFirstLabel: true,
            tickmarkPlacement: 'on',
        };

        const tempData = genDataMonthlyEachPlant?.value?.data?.map(item => {
            return {
                name: item?.plantName,
                data: item?.energy,
                // TBD
                // color: item?.plantColor,
                tooltip: {
                    valueSuffix: "kWh",
                },
            }
        })
        setGraphData(tempData);
        setXAxis(tempXAxis);
    }

    return {
        yAxis,
        graphData,
        xAxis,
        isLoading,
    };
};
