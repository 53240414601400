import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../../store/authToken";

const BASE_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://skifryfpel-dev-apimngment.azure-api.net/";

const sidemApiUrl = `SkyfriSidemApi/v1`

export const headerApi = createApi({
    reducerPath: "headerApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_API_URL}`,
        prepareHeaders: async (headers) => {
            let token = await getToken();
            headers.set("authorization", `Bearer ${token}`);
            headers.set(
                "Ocp-Apim-Subscription-Key",
                process.env.REACT_APP_API_MKEY
            );
            headers.set("Content-Type", "text/json");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSvgLogoApi: builder.query<any[], void>({
            query: () => `${sidemApiUrl}/GetPortfolioSvgLogo`,
        }),
    }),
});

export const {
    useGetSvgLogoApiQuery,
} = headerApi;