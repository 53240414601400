import { ColumnChart } from "../../../../shared/components/columnChart";
import { useSiteYearlyGraphWidget } from "./useSiteYearlyGraphWidget";

interface ColumnChartWidgetProps {
    marginRight?: number;
    marginLeft?: number;
    height?: number;
}

export const ColumnChartWidget = (props: ColumnChartWidgetProps) => {
    const {
        height,
    } = props;

    const {
        yAxis,
        xAxis,
        graphData,
        isLoading,
    } = useSiteYearlyGraphWidget()

    return (
        <div >
            <ColumnChart
                xAxis={xAxis}
                yAxis={yAxis}
                data={graphData}
                loading={isLoading}
                height={height}
            />
        </div>
    )
}
