import './loader.css';
interface LoaderProps {
    height?: number | string;
    width?: number | string;
}

export const Loader = (props: LoaderProps) => {
    const {
        height = null,
        width = null
    } = props;
    return (
        <div className="animate-pulse flex space-x-4">
            <div className="flex-1">
                <div className={`${!height && "loadingHeight"} ${!width && "loadingWidth"} bg-slate-500 rounded`} style={{ height: height, width: width }}></div>
            </div>
        </div>
    )
}
