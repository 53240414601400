import { StrictMode } from "react";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ErrorFallback";
import { Router } from "../routes";
import rootStore from "../store/rootStore";

interface Config extends Configuration {
    auth: any;
    cache: any;
    apiURL: string | undefined;
    apimKey: string | undefined;
    scope: (string | undefined)[]
}

export const MSAAPP = () => {
    const config: Config = {
        auth: {
            clientId: process.env.REACT_APP_CLIENT_ID,
            authority: process.env.REACT_APP_AUTHORITY,
            redirectUri: process.env.REACT_APP_REDIRECT_URI,
            postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
            validateAuthority: false,
            knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY],
        },
        cache: {
            cacheLocation: process.env.REACT_APP_SESSION_STORAGE,
            storeAuthStateInCookie: "false",
        },
        apiURL: process.env.REACT_APP_API_URL,
        apimKey: process.env.REACT_APP_API_MKEY,
        scope: [process.env.REACT_APP_SCOPE1, process.env.REACT_APP_SCOPE2],
    };
    const instance = new PublicClientApplication(config);

    return process.env.REACT_APP_CLIENT_ID ? (
        <StrictMode>
            <Provider store={rootStore}>
                <MsalProvider instance={instance}>
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onError={(error, errorInfo) => console.log({ error, errorInfo })}
                    >
                        <Router {...config} />
                    </ErrorBoundary>
                </MsalProvider>
            </Provider>
        </StrictMode>
    ) : (
        <div>
            <h1>
                Unable to read the env file. Please add environments to the project!{" "}
            </h1>
        </div>
    );
};
