/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetPlantLocationQuery, useGetPlantProfileInfoLobbyQuery } from "../../../../features/plantScreen/plantScreenApi";

interface Data {
    numberOfPlants: number;
    acCapacity: number;
    dcCapacity: number;
    locationInfo: { country: string; city: string; };
    kpiLoading: boolean;
}

export const useCapacityWidgetAms = (): Data => {
    const [url] = useSearchParams();
    const plantId = url.get("plantId");
    const portfolioId = url.get("portfolioId");

    const { data: plantsInfo } = useGetPlantProfileInfoLobbyQuery({ portfolioId, plantId });
    const { data: locationInfo, isLoading: kpiLoading } = useGetPlantLocationQuery({ plantId });

    const [numberOfPlants, setNumberOfPlants] = useState(0);
    const [acCapacity, setAcCapacity] = useState(0);
    const [dcCapacity, setDcCapacity] = useState(0);


    useEffect(() => {
        if (!plantsInfo || !plantsInfo?.length) return
        getData()
    }, [plantsInfo])

    const getData = () => {
        setNumberOfPlants(plantsInfo[0]?.numberOfPlants)
        setAcCapacity(plantsInfo[0]?.acCapacityKw)
        setDcCapacity(plantsInfo[0]?.dcCapacityKw)
    }

    // just to create an error remove
    // this is because we want to get to the error page
    // const [var1, setVar1] = useState<any>(undefined);
    // console.log(var1.length, `var1.length`)

    return {
        numberOfPlants,
        acCapacity,
        dcCapacity,
        locationInfo,
        kpiLoading,
    };
};
