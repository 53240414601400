// @ts-nocheck
import { useState, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import screenfull from 'screenfull';
import useComponentVisible from '../../../../shared/components/clickAwayListener/clickAwayListener.component';
import { getFullScreen, getRunningState } from '../../../../features/sharedActions/sharedAction';
import { sharedStateSelector } from '../../../../features/sharedState/sharedSlice';

import { ReactComponent as Fullscreen } from '../../../../shared/icons/Fullscreen.svg';
import { ReactComponent as Logout } from '../../../../shared/icons/Logout.svg';
import { ReactComponent as Minimize } from "../../../../shared/icons/Minimize.svg";
import { ReactComponent as VerticalDots } from '../../../../shared/icons/Vertical_Dots.svg'
import { ReactComponent as PlayIcon } from '../../../../shared/icons/Play.svg'
import { ReactComponent as PauseIcon } from '../../../../shared/icons/Pause.svg'

import './dropdownButton.css'
interface Props {
    children?: ReactNode;
    userName: string;
    logOut: () => void;
    roles: RolesMap;
}
export const DropdownButton = (props: Props) => {
    const { userName, logOut, roles } = props
    const dispatch = useDispatch();
    const { runningState } = useSelector(sharedStateSelector)

    const [isFullScreen, setIsFullScreen] = useState(true)

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const toggleFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
            setIsFullScreen(screenfull.isFullscreen)
        }
    }

    const checkFullScreen = () => {
        if (isFullScreen) {
            dispatch(getFullScreen(true))
        }
        else {
            dispatch(getFullScreen(false))
        }
    }

    const hanldeRunningState = () => {
        dispatch(getRunningState(!runningState))
    }

    return (
        <div ref={ref}>
            <button
                id="dropdown-button"
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                    setIsComponentVisible(!isComponentVisible)
                    setTimeout(() => {
                        setIsComponentVisible(false)
                    }, 6000);
                }}
                className="max-w-lg rounded-xl border-solid border-2 border-cardBorder overflow-hidden bg-cardColorfont-medium text-sm buttonPadding text-center inline-flex items-center"
            >
                <VerticalDots className='verticalDots' />
            </button>
            {
                isComponentVisible &&
                <>
                    <div
                        id="dropdown-menu"
                        className={`absolute dropdownIndex right-10 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 space-y-1 p-2`}
                    >
                        <div
                            className="block px-4 py-2 text-primary buttonTextSize cursor-pointer">
                            {userName}
                        </div>
                        <hr className='pb-2' />
                        <div className="flex px-4 py-2 cursor-pointer buttonTextSize" onClick={() => {
                            toggleFullScreen()
                            setIsComponentVisible(false)
                            checkFullScreen()
                        }}>
                            {isFullScreen ? <Fullscreen className='logoSize' /> : <Minimize className='logoSize' />} <span className='text-primary pl-3'>{isFullScreen ? `Full screen` : `Minimize`}</span>
                        </div>
                        {
                            roles.SkyfriDemo && <div className="flex px-4 py-2 cursor-pointer buttonTextSize" onClick={() => {
                                hanldeRunningState()
                            }}>
                                {runningState ? <PauseIcon className='logoSize' /> : <PlayIcon className='logoSize' />} <span className='text-primary pl-3'>{runningState ? 'Pause' : "Play"}</span>
                            </div>
                        }

                        <div className="flex px-4 py-2 cursor-pointer buttonTextSize pb-3" onClick={() => {
                            logOut()
                            setIsComponentVisible(false)
                        }}>
                            <Logout className='logoSize' /> <span className='text-primary pl-3'>Log out</span>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
