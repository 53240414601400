import { ReactNode, FC } from 'react';

interface CustomCardProps {
    children: ReactNode;
    height?: number | string;
    flex?: boolean;
}

export const CustomCard: FC<CustomCardProps> = ({ children, height = null, flex = false }) => {
    return (
        <div className="h-full rounded-xl border-solid border-2 border-cardBorder overflow-hidden bg-cardColor" style={{ height: height ? height : "fit-content", display: flex && "flex", alignItems: flex && "center", justifyContent: flex && "center" }}>
            {children}
        </div>
    );
}; 