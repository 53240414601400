/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetCommissionDateQuery, useGetPlantProfileInfoLobbyQuery } from "../../../features/plantScreen/plantScreenApi";

interface Data {
    dcCapacity: number;
    capacityLoading: boolean;
    commissionLoading: boolean;
    commissionDate: string;
}

export const useCapacityCommissionWidget = (): Data => {
    const [url] = useSearchParams();
    const plantId = url.get("plantId");
    const portfolioId = url.get("portfolioId");

    const { data: plantsInfo, isLoading: capacityLoading } = useGetPlantProfileInfoLobbyQuery({ portfolioId, plantId }, { skip: !plantId });
    const { data: commissionDate, isLoading: commissionLoading, } = useGetCommissionDateQuery({ plantId }, { skip: !plantId });

    const [dcCapacity, setDcCapacity] = useState(0);

    useEffect(() => {
        if (!plantsInfo || !plantsInfo?.length) return
        getData()
    }, [plantsInfo])

    const getData = () => {
        setDcCapacity(plantsInfo[0]?.dcCapacityKw)
    }

    return {
        dcCapacity,
        capacityLoading,
        commissionLoading,
        commissionDate,
    };
};
