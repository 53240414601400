import { useFooter } from './useFooter';
import { Progressbar } from '../../shared/components/progressBar';
import { useSelector } from 'react-redux';
import { sharedStateSelector } from '../../features/sharedState/sharedSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './footer.css'

export const Footer = () => {

    const {
        nextScreenInfo,
        setScreenType,
        nextPlantInfo,
        secondaryLogo
    } = useFooter();

    const { graphLoading, runningState } = useSelector(sharedStateSelector)

    const navigate = useNavigate();


    const handleOverlayClick = (e) => {
        e.preventDefault();
        const overlayWidth = e.currentTarget.offsetWidth;
        const clickX = e.clientX - e.currentTarget.getBoundingClientRect().left;

        if (clickX < overlayWidth / 2) {
            navigate(-1)
        } else {
            setScreenType()
        }
    };

    const handleKeyDown = (e) => {
        e.preventDefault();
        if (e.key === 'ArrowLeft') {
            navigate(-1);
        } if (e.key === 'ArrowRight') {
            setScreenType();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setScreenType]);

    return (
        <>
            <div className='wrapper'>
                <Progressbar id="progressbar2"

                    // the duration of the progress bar is set to 30s for real use
                    duration={graphLoading ? "99999999999999s" : "30s"}

                    // the duration of the progress bar is set to 10s for testing purposes
                    // duration={graphLoading ? "99999999999999s" : "10s"}

                    secondaryLogo={secondaryLogo}

                    callback={() => {
                        setScreenType()
                    }}
                    nextPlant={nextPlantInfo()}
                    nextScreen={nextScreenInfo()}
                    runningState={runningState}
                />
            </div>
            <div className="overlay" onClick={handleOverlayClick}></div>
        </>
    )
}
