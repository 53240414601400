/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { convertEnergy, convertEnergyUnits } from "../../../../shared/utils/utils";
import { useSearchParams } from "react-router-dom";
import { useGetPlantMonthlyDataQuery } from "../../../../features/plantScreen/plantScreenApi";

interface Data {
    energyLastYear: number;
    energyLastYearUnit: string;
    energyCurrentYear: number;
    energyCurrentYearUnit: string;
    isLoading?: boolean;
}

export const useKpiMonthly = (): Data => {
    const [url] = useSearchParams()
    const plantIdUrl = url.get("plantId")

    const { data: genData, isLoading, refetch } = useGetPlantMonthlyDataQuery({ plantId: plantIdUrl }, { skip: !plantIdUrl });

    const [energyLastYear, setEnergyLastYear] = useState<number>(0);
    const [energyLastYearUnit, setEnergyLastYearUnit] = useState<string>("kWh");
    const [energyCurrentYear, setEnergyCurrentYear] = useState<number>(0);
    const [energyCurrentYearUnit, setEnergyCurrentYearUnit] = useState<string>("kWh");


    useEffect(() => {
        if (!genData) return
        getData()
    }, [genData])

    useEffect(() => {
        if (!plantIdUrl) return
        refetch()
    }, [plantIdUrl])

    const getData = () => {
        setEnergyLastYear(convertEnergy(genData?.lastYearEnergyMonthlySum))
        setEnergyLastYearUnit(convertEnergyUnits(genData?.lastYearEnergyMonthlySum))
        setEnergyCurrentYear(convertEnergy(genData?.currentEnergyMonthlySum))
        setEnergyCurrentYearUnit(convertEnergyUnits(genData?.currentEnergyMonthlySum))
    }

    return {
        energyLastYear,
        energyLastYearUnit,
        energyCurrentYear,
        energyCurrentYearUnit,
        isLoading,
    };
};
