/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGetGenDataLobbyQuery } from "../../../../features/aggregateScreen/aggregateScreenApi";
import { convertEnergy, convertEnergyUnits } from "../../../../shared/utils/utils";

interface Data {
    gridExport: number;
    gridExportUnit: string;
    gridImport: number;
    gridImportUnit: string;
    pvGen: number;
    pvGenUnit: string;
    isLoading?: boolean;
}

export const useKpiWidget = (): Data => {
    const { data: genData, isLoading } = useGetGenDataLobbyQuery(
        { portfolioId: "all", plantId: "all" },
        { refetchOnMountOrArgChange: 1800 });

    const [gridExport, setGridExport] = useState<number>(0);
    const [gridExportUnit, setGridExportUnit] = useState<string>("kWh");
    const [gridImport, setGridImport] = useState<number>(0);
    const [gridImportUnit, setGridImportUnit] = useState<string>("kWh");
    const [pvGen, setPvGen] = useState<number>(0);
    const [pvGenUnit, setPvGenUnit] = useState<string>("kWh");

    // const [var1, setVar1] = useState<any>(undefined);

    useEffect(() => {
        if (!genData || !genData?.length) return
        getData()
    }, [genData])

    const getData = () => {
        setGridExport(convertEnergy(genData[0]?.gridExport))
        setGridExportUnit(convertEnergyUnits(genData[0]?.gridExport))
        setGridImport(convertEnergy(genData[0]?.gridImport))
        setGridImportUnit(convertEnergyUnits(genData[0]?.gridImport))
        setPvGen(convertEnergy(genData[0]?.pvGen))
        setPvGenUnit(convertEnergyUnits(genData[0]?.pvGen))
    }

    return {
        gridExport,
        gridExportUnit,
        gridImport,
        gridImportUnit,
        pvGen,
        pvGenUnit,
        isLoading,
    };
};
