export const ROLES = {
  Administrator: 'Administrator',
  BILLING: 'BILLING',
  CMMS: 'CMMS',
  COMMERCIAL: 'COMMERCIAL',
  ONBOARDING: 'ONBOARDING',
  PermitApprover: 'PermitApprover',
  SIDEM: 'SIDEM',
  SkyfriAdmin: 'SkyfriAdmin',
  AssetManager: 'AssetManager',
  SiteManager: 'SiteManager',
  Technician: 'TechnicianSupervisor'
};

export const LOCALSTORAGEKEYS = {
  TOKEN: 'token',
  HASH_STATE: 'hash',
  HASH_USERNAME: "u",
  HASH_PASSWORD: "p",
}