import { combineReducers } from "@reduxjs/toolkit";
import { aggregateScreenApi } from "../features/aggregateScreen/aggregateScreenApi";
import { headerApi } from "../features/header/headerApi";
// import { errorReducer } from "../features/Notifications/errorActions";
import SharedReducer from "../features/sharedState/sharedReducer";
import { sharedApi } from "../features/sharedActions/sharedApi";
import { plantScreenApi } from "../features/plantScreen/plantScreenApi";

const rootReducer = combineReducers({
    shared: SharedReducer,
    [headerApi.reducerPath]: headerApi.reducer,
    [sharedApi.reducerPath]: sharedApi.reducer,
    [aggregateScreenApi.reducerPath]: aggregateScreenApi.reducer,
    [plantScreenApi.reducerPath]: plantScreenApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
