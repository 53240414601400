import { Suspense, lazy, useEffect, useMemo, useRef, useState } from 'react';
import { useCapacityWidget } from './components/capacityWidget/useCapacityWidget';
import { useKpiWidget } from './components/kpiWidget/useKpiWidget';
import { useAggregateScreen } from './useAggregateScreen';
import { useSearchParams } from 'react-router-dom';
import { useGraphWidget } from './components/graphWidget/useGraphWidget';
import { useGraphWidgetV2 } from './components/graphWidgetV2/useGraphWidgetV2';
import { useGraphWidgetV3 } from './components/graphWidgetV3/useGraphWidgetV3';
import { checkNegative, screenBottomGap } from '../../shared/utils/utils';
import { sharedStateSelector } from '../../features/sharedState/sharedSlice';
import { useSelector } from 'react-redux';
import { useEnvironmentalImpactWidget } from './components/environmentalImpactWidget/useEnvironmentalImpactWidget';
import './aggregateScreen.css';
// TBD
// import { usePlantEnergyWidget } from './components/plantEnergyWidget/usePlantEnergyWidget';

const CapacityWidget = lazy(() =>
    import("../../shared/components/capacityWidget").then((module) => ({
        default: module.CapacityWidget,
    }))
);

const KpiWidget = lazy(() =>
    import("../../shared/components/kpiWidget").then((module) => ({
        default: module.KpiWidget,
    }))
);

const KpiWidgetV2 = lazy(() =>
    import("../../shared/components/kpiWidgetV2").then((module) => ({
        default: module.KpiWidgetV2,
    }))
);

const GraphWidget = lazy(() =>
    import("../../shared/components/graphWidget").then((module) => ({
        default: module.GraphWidget,
    }))
);

const StackedGraphWidget = lazy(() =>
    import("../../shared/components/stackedGraphWidget").then((module) => ({
        default: module.StackedGraphWidget,
    }))
);

const EnvironmentalImpactWidget = lazy(() =>
    import("./components/environmentalImpactWidget").then((module) => ({
        default: module.EnvironmentalImpactWidget,
    }))
);


export const AggregateScreen = () => {
    const [url] = useSearchParams()
    const version = url.get("version")
    const [availableHeight, setAvailableHeight] = useState(0);
    const divRef = useRef(null);
    const { graphLoading } = useSelector(sharedStateSelector)

    const calculateAvailableHeight = () => {
        if (divRef.current) {
            const elementBottom = divRef.current.getBoundingClientRect().bottom;
            const viewportHeight = window.innerHeight;
            setAvailableHeight(availableHeight => checkNegative(availableHeight + ((viewportHeight - elementBottom) - screenBottomGap())));
        }
    };

    useMemo(() => {
        if (!divRef.current)
            return

        //initial calculation
        calculateAvailableHeight();

        // Re-calculate available height on window resize
        window.addEventListener('resize', calculateAvailableHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateAvailableHeight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divRef.current]);

    useEffect(() => {
        if (!availableHeight && !graphLoading) return
        calculateAvailableHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphLoading])


    useAggregateScreen();

    const checkKpiVersion = (version) => {
        if (version === "1") {
            return <KpiWidget customHook={useKpiWidget} comp='agg' />
        }
        else if (version === "2" || version === "5") {
            return <KpiWidgetV2 customHook={useKpiWidget} />
        }
        else if (version === "3") {
            return <KpiWidgetV2 version={version} customHook={useKpiWidget} />
        }
        else if (version === "4") {
            return <KpiWidget customHook={useKpiWidget} comp='agg' />
        }
        else {
            return null
        }
    }

    const checkGraphVersion = (version) => {
        if (version === "1" || version === "4") {
            return <GraphWidget
                height={availableHeight}
                customHook={useGraphWidget}
                marginRight={40}
                marginLeft={70} />
        }
        else if (version === "2" || version === "5") {
            return <GraphWidget
                height={availableHeight}
                customHook={useGraphWidgetV2}
                marginRight={40}
                marginLeft={70}
            />
        }
        else if (version === "3") {
            return <StackedGraphWidget
                height={availableHeight}
                customHook={useGraphWidgetV3}
                marginRight={40}
                marginLeft={70}
            />
        }
        else {
            return null
        }
    }

    const checkPlantsPower = (version) => {
        if (version === "3") {
            return (
                <div className='grid grid-cols-2'>
                    <div className='col-span-1'>
                        {checkKpiVersion(version)}
                    </div>
                </div>
            )
        }
        else {
            return checkKpiVersion(version)
        }
    }

    const checkEnvironmentalImpactWidgetsVersion = (version) => {

        if (version === "4" || version === "5") {
            return (
                <EnvironmentalImpactWidget customHook={useEnvironmentalImpactWidget} />
            )
        }
        else {
            return null
        }
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="grid grid-cols-12">
                <div className='col-span-4'>
                    <CapacityWidget comp='agg' customHook={useCapacityWidget} />
                </div>
                <div className='col-span-8'>
                    {
                        checkPlantsPower(version)
                    }
                </div>
                <div className='col-span-12 graphPaddings' style={{ height: availableHeight }}>
                    {
                        checkGraphVersion(version)
                    }
                </div>
                <div className='col-span-12 graphPaddings' ref={divRef}>
                    {
                        graphLoading ? null :
                            checkEnvironmentalImpactWidgetsVersion(version)
                    }
                </div>
            </div>
        </Suspense>
    )
}
