import { Suspense, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux';
import { sharedStateSelector } from '../../features/sharedState/sharedSlice';
import { LocationWidget } from '../../shared/components/locationWidget';
import { useLocationWidget } from './locationWidget/uselocationWidget';
import { CommissionedWidget } from '../../shared/components/commissionedWidget';
import { useCapacityCommissionWidget } from './capacityCommissionWidget/useCapacityCommissionWidget';
import { SiteImageWidget } from '../../shared/components/siteImageWidget';
import { useSiteImage } from './siteImage/useSiteImage';

export const SiteOverview = () => {
    const { colors } = useSelector(sharedStateSelector)

    useLayoutEffect(() => {
        document.body.style.backgroundColor = colors?.backgroundColor;
    }, [colors]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className='grid grid-cols-12'>
                <div className='grid grid-flow-col auto-cols-max'>
                    <div>
                        <LocationWidget customHook={useLocationWidget} />
                    </div>
                    <div>
                        <CommissionedWidget customHook={useCapacityCommissionWidget} />
                    </div>
                </div>
                <div className='col-span-12'>
                    <SiteImageWidget customHook={useSiteImage} />
                </div>
            </div>
        </Suspense>
    )
}
