/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from "react-router-dom";
import { useGetPlantLocationQuery } from "../../../features/plantScreen/plantScreenApi";

interface Data {
    locationInfo: { country: string; city: string; };
    locationLoading: boolean;
}

export const useLocationWidget = (): Data => {
    const [url] = useSearchParams();
    const plantId = url.get("plantId");

    const { data: locationInfo, isLoading: locationLoading } = useGetPlantLocationQuery({ plantId }, { skip: !plantId });

    return {
        locationInfo,
        locationLoading,
    };
};
