/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useEffect, useState } from 'react';
import { PoweredBySkyfri } from '../../icons/PoweredBySkyfri';
import './progressBar.css';
interface ProgressbarProps {
    id: string;
    duration: string;
    callback: () => void;
    nextScreen: unknown;
    nextPlant: unknown;
    secondaryLogo?: any;
    runningState?: boolean;
}

export const Progressbar = (props: ProgressbarProps) => {
    const { id, duration, callback, nextScreen, nextPlant, secondaryLogo, runningState } = props;

    const [playState, setPlayState] = useState('running');
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [resizeTimeout, setResizeTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const handleResize = () => {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }
            setResizeTimeout(setTimeout(() => {
                setViewportWidth(window.innerWidth);
            }, 500));
        };

        window.addEventListener('resize', handleResize);
        return () => {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [resizeTimeout]);

    useEffect(() => {
        setPlayState(runningState ? 'running' : 'paused')

    }, [runningState]);

    useEffect(() => {
        const progressbar = document.getElementById(id);
        progressbar.className = 'progressbar';

        const progressbarinner = document.createElement('div');
        progressbarinner.className = 'inner';
        progressbarinner.style.animationDuration = duration;
        progressbarinner.style.animationPlayState = playState;

        const next = document.createElement('div');
        next.className = 'progress-text next-text-leftplant next';
        next.textContent = `Next: `;

        const textLeftPlant = document.createElement('div');
        textLeftPlant.className = 'progress-text next-text-leftplant left-plant';
        textLeftPlant.textContent = `${nextPlant} ${nextScreen}`;

        const deliveredText = document.createElement('div');
        deliveredText.className = 'progress-text deliveredby-poweredby delivered-by';
        if (secondaryLogo !== null)
            deliveredText.textContent = `Delivered by`;

        const imgElement = document.createElement('img');
        imgElement.className = 'progress-text secondary-logo';
        if (secondaryLogo !== null)
            imgElement.src = secondaryLogo;

        const textEnd = document.createElement('div');
        textEnd.className = 'progress-text deliveredby-poweredby powered-by';
        textEnd.textContent = `Powered by`;

        const divider = document.createElement('div');
        if (secondaryLogo !== null)
            divider.className = 'progress-text divider';

        const logo = document.createElement('div');
        logo.className = 'progress-text logo';
        logo.appendChild(PoweredBySkyfri);

        if (typeof callback === 'function') {
            progressbarinner.addEventListener('animationend', callback);
        }

        progressbarinner.addEventListener('animationiteration', () => {
            setPlayState('paused');
            setTimeout(() => {
                setPlayState('running');
            }, 0);
        });

        progressbarinner?.appendChild(next);
        progressbarinner?.appendChild(textLeftPlant);
        progressbarinner?.appendChild(deliveredText);
        progressbarinner?.appendChild(imgElement);
        progressbarinner?.appendChild(divider);
        progressbarinner?.appendChild(textEnd);
        progressbarinner?.appendChild(logo);
        progressbar?.appendChild(progressbarinner);

        imgElement.addEventListener('load', () => {
            const rect1 = imgElement.getBoundingClientRect();
            const elementWidth = imgElement.clientWidth;
            const spaceToRight = viewportWidth - rect1.right;
            deliveredText.style.right = `${spaceToRight + elementWidth}px`;
        });

        return () => {
            progressbar.removeChild(progressbarinner);
        };
    }, [secondaryLogo, id, duration, callback, playState, viewportWidth]);

    return <div id={id}></div>;
};
