import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
NoDataToDisplay(Highcharts);

interface Props {
    xAxis: {};
    yAxis: any[];
    data: any[];
    height?: string | number;
    loading?: boolean;
    width?: string | number;
};


export const ColumnChart = (props: Props) => {

    const {
        xAxis,
        yAxis,
        data,
        height,
        loading,
    } = props;

    const options = {
        chart: {
            height: height,
            reflow: true,
            events: {
                load() {
                    if (loading) {
                        this.showLoading();
                    }
                    else {
                        this.hideLoading();
                    }
                },
                redraw() {
                    if (loading) {
                        this.showLoading();
                    }
                    else {
                        this.hideLoading();
                    }
                },
            },
            boost: {
                useGPUTranslations: true
            },
            type: 'column',
            style: {
                fontFamily: 'Inter',
                fontWeight: 500,
            },
            backgroundColor: 'transparent'
        },
        legend: {
            enabled: false
        },
        loading: {
            showDuration: 0, // Show the loader immediately
            hideDuration: 1000, // Hide the loader after 1 second (adjust as needed)
            labelStyle: {
                fontSize: '16px',
                color: '#FFFFFF'
            },
            style: {
                backgroundColor: 'transparent',
            }
        },
        tooltip: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        title: {
            text: '',
        },
        lang: {
            noData: 'No data is available in the chart'
        },
        noData: {
            style: {
                fontWeight: 500,
                fontSize: '15px',
                color: '#9F9F9F'
            },
            position: {
                "x": 0,
                "y": 0,
                "align": "center",
                "verticalAlign": "middle"
            }
        },
        xAxis: xAxis,
        yAxis: yAxis,
        plotOptions: {
            series: {
                pointPadding: 0.07,
                borderWidth: 0,
                groupPadding: 0.2,
                shadow: false,
                centerInCategory: true,
                animation: {
                    duration: 100
                }
            }
        },
        exporting: {
            enabled: false,
        },
        series: data,
    };

    return (
        <div> <HighchartsReact highcharts={Highcharts} options={options} /> </div>
    )
}
