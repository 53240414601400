import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/rootReducers";
// sharedSlice
export interface ISharedState {
    live: boolean;
    clientName?: string;
    colors: {
        backgroundColor?: string;
        energyColor?: string;
        energyColorGradient?: string;
        gridExportColor?: string;
        gridExportColorGradient?: string;
        gridImportColor?: string;
        gridImportColorGradient?: string;
        irradianceColor?: string;
        irradianceColorGradient?: string;
    },
    amsMeter?: boolean;
    graphLoading?: boolean;
    userName?: string;
    selfSustainability?: number;
    fullScreen?: boolean;
    plantIds: string[],
    runningState?: boolean;
    numberOfPlantsState?: number;
}

const initialState: ISharedState = {
    live: true,
    clientName: "",
    colors: {
        backgroundColor: "#0C1E33",
        energyColor: "#00A3FF",
        energyColorGradient: "rgba(0, 163, 255,0)",
        gridExportColor: "#00FFC2",
        gridExportColorGradient: "rgba(0, 255, 194,0)",
        gridImportColor: "#CE3F4E",
        gridImportColorGradient: "rgba(206, 63, 78,0)",
        irradianceColor: "#CE3F4E",
        irradianceColorGradient: "rgba(206, 63, 78, 0)",
    },
    amsMeter: false,
    graphLoading: true,
    userName: "",
    selfSustainability: 0,
    fullScreen: false,
    plantIds: [],
    runningState: true,
    numberOfPlantsState: 0,
};

// name
// reducer
// actions
// caseReducers

export const sharedStateSlice = createSlice({
    name: "sharedstate",
    initialState,
    reducers: {
        setLive: (state, { payload }) => {
            return {
                ...state,
                live: payload,
            };
        },
        setClientName: (state, { payload }) => {
            return {
                ...state,
                clientName: payload,
            };
        },
        setTenantColor: (state, { payload }) => {
            return {
                ...state,
                colors: payload,
            };
        },
        setAmsMeter: (state, { payload }) => {
            return {
                ...state,
                amsMeter: payload,
            };
        },
        setGraphLoading: (state, { payload }) => {
            return {
                ...state,
                graphLoading: payload,
            };
        },
        setUserName: (state, { payload }) => {
            return {
                ...state,
                userName: payload,
            };
        },
        setSelfSustainability: (state, { payload }) => {
            return {
                ...state,
                selfSustainability: payload,
            };
        },
        setFullScreen: (state, { payload }) => {
            return {
                ...state,
                fullScreen: payload,
            };
        },
        setPlantIds: (state, { payload }) => {
            return {
                ...state,
                plantIds: payload,
            };
        },
        setRunningState: (state, { payload }) => {
            return {
                ...state,
                runningState: payload,
            };
        },
        setNumberOfPlants: (state, { payload }) => {
            return {
                ...state,
                numberOfPlantsState: payload,
            };
        },
    },
});

// Shared actions
export const {
    setLive,
    setClientName,
    setTenantColor,
    setAmsMeter,
    setGraphLoading,
    setUserName,
    setSelfSustainability,
    setFullScreen,
    setPlantIds,
    setRunningState,
    setNumberOfPlants,
} = sharedStateSlice.actions;

// Shared state

export const sharedStateSelector = (state: RootState) =>
    state.shared.sharedState;

// Shared reducer

export const sharedStateReducer = sharedStateSlice.reducer;